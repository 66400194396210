.hero {
  background: url("../../../../public/images/simg/mm.jpeg") no-repeat top center;

  background-size: cover;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  overflow: hidden;
  /* padding-right: 50px; */
  color: #fff;
  /* margin-bottom: 100px; */
  /* text-align: left; */
}
.hero h3 {
  font-weight: 600;
  font-size: 45px;
  letter-spacing: 1px;
  color: #3c8c47;
  /* text-transform: uppercase; */

  text-shadow: 1px -1px #000;
  /* padding-bottom: 20px; */
}

.hero #heading {
  text-align: left;
  /* width: 100%; */
  /* display: block; */
  margin: 0;
  padding: 0;
}
.hero .row p {
  width: 100%;
  /* margin-top: 132px; */
  padding-top: 30px;
  font-size: 26px;
  font-weight: 12px;
  text-shadow: 5px 5px 8px #00ff00;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 50vh;
  }
  .hero {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
    margin-top: 122px;
  }

  .hero h3 {
    /* margin-top: 55px; */
    padding-bottom: 25px;
    text-align: left;
    margin: 0;
  }
  .hero .row p {
    padding: 0px;
    margin: 0px;
    line-height: 1;
    overflow: hidden;
  }
}

.coursesCard {
  padding: 50px 0;
}
.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  /* border: solid 1px grey; */
  box-shadow: 2px 5px 25px -2px rgba(32, 26, 26, 0.555);
}
.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #3c8c47;
  padding: 15px;
}
.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}
.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}
.coursesCard .rate {
  margin: 20px 0;
  color: #3c8c47;
}
.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}
.coursesCard .details span {
  color: #3c8c47;
  font-weight: 500;
  font-size: 14px;
}
.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}
.coursesCard h3 {
  font-weight: 500;
  color: #3c8c47;
}
/*-------------online---------*/
.online {
  text-align: center;
}
.online .box {
  /* box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%); */
  box-shadow: 2px 5px 25px -2px rgba(32, 26, 26, 0.555);
  background-color: #fff;
  padding: 10px 10px;
  transition: 0.5s;
}
.online .img {
  width: 120px;
  height: 80px;
  margin: auto;
  position: relative;
  object-fit: cover;
}
.online img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* color: #3c8c47; */
}
.online .kao {
  /* width: 100%;
  height: 100%; */
  /* object-fit: cover; */
  /* background-color: #3c8c47; */
  /* filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%); */
}

/* .online h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}
.online span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 15px;
  color: #3c8c47;
  border-radius: 5px;
}
.online .box .show {
  opacity: 0;
} */
.online .box:hover {
  background-color: #3c8c47;
  border-radius: 5px;
  cursor: pointer;
}
/* .online .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
} */
.online .box:hover h1 {
  color: #fff;
}
/*-------------online---------*/
@media screen and (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .online .box:hover {
    background-color: #3c8c47;
    border-radius: 5px;
    cursor: pointer;
  }
}

/*--------head------------*/
.head {
  padding: 20px 0;
  color: #3c8c47;
}
.logo img {
  display: flex;
  max-height: 45px;
  opacity: 100%;
  margin-right: 10px;
}
.logo h1 {
  display: flex;
  font-size: 35px;
  font-weight: 900;
}
.logo span {
  /* margin: 55px; */
  /* display: block; */
}
.head .icon {
  margin-left: 10px;
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: white;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #11f52f;
}
.start {
  background-color: #3c8c47;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .head {
    padding-left: 0;
    margin: 0;
  }
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: rgba(255, 255, 255, 0.2);
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    /* color: #3c8c47; */
    font-size: 30px;
    position: absolute;
    right: 12px;
    top: -105px;
    padding: 0;
    padding-top: 2px;
  }
  .mobile-nav {
    position: absolute;
    /* top: 1vh; */
    left: 0;
    width: 100%;
    background-color: #3c8c47;
  }
  .social {
    visibility: hidden;
  }
  .logo span {
    margin: 0;
    display: none;
  }
  .logo h1 {
    font-size: 20px;
  }
  .logo h1 img {
    /* margin-top: 16px; */
  }
}

.prt {
  padding: 0;
  margin: 0;
}
#heading {
  padding-top: 40px;
}
.leftt {
  padding-left: 50px;
  padding-right: 50px;
  /* text-align: left; */
  line-height: 2;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
}
.leftt .leftti {
  margin: auto;
}

.leftt .leftti img {
  width: 470px;
  height: 530px;
  display: flex;

  border-radius: 50%;
  /* object-fit: cover; */
}
.leftt .lefttd {
  padding: 50px;
  line-height: 2;
}
.rightt {
  /* margin: 50px auto 50px auto; */
  padding-left: 50px;
  padding-right: 50px;

  line-height: 2;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
}
.rightt .rightti {
  margin: auto;
}

.rightt .rightti img {
  width: 470px;
  height: 530px;
  border-radius: 50%;
}
.rightt .righttd {
  padding: 50px;
  line-height: 2;
}
@media screen and (max-width: 768px) {
  #heading {
    padding-top: 90px;
  }
  .leftt {
    flex-direction: column;
    padding: 0;
  }
  .leftt .lefttd {
    padding: 10px;
    /* margin: 0; */
    text-align: center;
  }
  .leftt .leftti {
    width: 370px;
    display: flex;
    height: calc((100vw - 48px) / 1);
    /* padding: 10px; */
  }
  .leftt .leftti img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* object-fit: cover; */
  }
  .rightt {
    flex-direction: column-reverse;
    padding: 0;
  }
  .rightt .righttd {
    padding: 10px;
    /* margin: 0; */
    text-align: center;
  }
  .rightt .rightti {
    width: 370px;
    display: flex;
    height: calc((100vw - 48px) / 1);
  }
  .rightt .rightti img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* height: calc((100vw - 48px) / 1); */
    /* object-fit: cover; */
  }
}

/* Styling for the main container */
.about-us {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px 25px 20px;
  /* font-family: Arial, sans-serif; */
  /* line-height: 1.6; */
  color: #333;
}

/* Styling for headings */
.about-us h2,
.about-us h3 {
  color: #3c8c47;
  text-align: center;
}
.about-us h2 {
  font-size: 30px;
}
.about-us h3 {
  font-size: 25px;
}

/* Styling for paragraphs */

/* Styling for unordered lists */
.about-us ul {
  list-style-type: disc;
  margin-left: 30px;
}

/* Styling for list items */
.about-us li {
  margin-bottom: 10px;
}
.about-us li span {
  color: #3c8c47;
  text-decoration: underline;
  font-weight: bold;
}
/* Styling for links */
.about-us a {
  color: #0088cc;
  text-decoration: none;
}

.about-us a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  #heading {
    padding-top: 80px;
  }
  #heading h1 {
    margin: 0;
    padding: 0;
  }
  .about-us h2,
  .about-us h3 {
    text-align: center;
    padding: 20px;
  }
}
